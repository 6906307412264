// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null
}

const organizations = (state = initialState, action) => {
  switch (action.type) {
    
    case 'GET_ORGANIZATIONS':
      return { ...state, organizations: action.organizations }
    
    
    default:
      return { ...state }
  }
}
export default organizations
