// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null
}

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ACCOUNT_NAMES':      
      return { ...state, account_names: action.account_names }
    case 'GET_ACCOUNT':
      return { ...state, selectedAccount: action.selectedAccount }
    case 'GET_ACCOUNTS':
      return { ...state, accounts: action.accounts }
    case 'UPDATE_ACCOUNT':
      Object.assign(...state.accounts, action.updated_account)
      localStorage.setItem('accounts', JSON.stringify(state))
      return { ...state }
    case 'SET_ACCOUNT':
      localStorage.setItem('accounts', JSON.stringify({ ...state, account: action.account }))
      return { ...state, account: action.account }
    case 'OPEN_ACCOUNT_DELETE_MODAL':
      return { ...state, del_modal_status: action.del_modal_status }
    default:
      return { ...state }
  }
}
export default accounts
