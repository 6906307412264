const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  selected: {},
  suites: [],
  loading: false,
  predictions:[],  
}

const resortReducer = (state = initialState, action) => {
  switch (action.type) {
      
    
    case 'GET_ALL_RESORT_DATA':
      return {
        ...state,
        allData: action.allData
      }
    case 'GET_RESORT_DATA':
      return {
        ...state,
        data: action.data,
        total: action.total,
        last_page: action.last_page,
        params: action.params
      }
    case 'SELECT_RESORT':
      return {
        ...state,
        selected: action.resort
      }
    case 'SEARCH_MAP':
      return {
        ...state,
        predictions: action.predictions
      }
    
    
    
    default:
      return { ...state }
  }
}
export default resortReducer
