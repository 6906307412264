import { isObjEmpty } from "../../../../../utility/Utils"

const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  selected: {},
  suites: [],
  loading: false,
  predictions: [],  
  msg: ''
}

const suiteReducer = (state = initialState, action) => {
  switch (action.type) {
        
    case 'SELECT_SUITE':
      return {
        ...state,
        selected: action.suite
      }
    case 'GET_SUITE_DATA':
      if (state.selected && isObjEmpty(state.selected)) {
        return {
          ...state,
          selected: action.data.length > 0 ? action.data[0] : null,
          data: action.data,
          total: action.total,
          last_page: action.last_page,
          params: action.params
        }
      } else {
        return {
          ...state,
          data: action.data,
          total: action.total,
          last_page: action.last_page,
          params: action.params
        }
      }
      case 'RESERVATION_SUITES':
        return {
          ...state,
          loading: action.loading,
          reservation_suites: action.reservation_suites,
          require_valid_check: action.require_valid_check,
          msg: action.msg
        }
    default:
      return { ...state }
  }
}
export default suiteReducer
