// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import resort from '@src/views/apps/resort/store/reducer'
import suite from '@src/views/apps/suite/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import accounts from '@src/views/apps/account/store/reducer'
import organizations from '@src/views/apps/organization/store/reducer'
import jobs from '@src/views/apps/job/store/reducer'
import webdrivers from '@src/views/apps/webdriver/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  resort,
  suite,
  calendar,
  ecommerce,
  dataTables,
  organizations,
  accounts,
  jobs,
  webdrivers
})

export default rootReducer
